import React from 'react'
import styled from 'styled-components'
import { Button } from 'paybolt-uikit'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(/images/home/home-banner-ht-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  border-radius: 12px;
  color: #fff;
  padding: 21px 12px;
  margin: 40px 0;
  @media (max-width: 470px) {
    flex-direction: column;
  }
`

const Title = styled.h2`
  font-family: Poppins;
  font-weight: 400;
  font-size: 26px;
  line-height: 28px;
  margin-right: 60px;
  display: flex;
  align-items: center;
  @media (max-width: 1100px) {
    margin-right: 40px;
    font-size: 20px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 470px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
`

const Bold = styled.span`
  font-family: Poppins;
  font-weight: 700;
`

export default function HorizontalBanner() {
  return (
    <Container>
      <Title>
        The World’s Fastest&nbsp;<Bold>Growing Crypto App</Bold>
      </Title>
      <Button
        as="a"
        href="https://pancakeswap.finance/swap?inputCurrency=0xe580074A10360404AF3ABfe2d524D5806D993ea3"
        style={{ backgroundColor: '#17AB76' }}
        target="_blank"
      >
        BUY in BSC
      </Button>
      &nbsp; &nbsp;
      <Button
        as="a"
        href="https://app.uniswap.org/#/swap?inputCurrency=0xe580074A10360404AF3ABfe2d524D5806D993ea3&chain=mainnet"
        style={{ backgroundColor: '#17AB76' }}
        target="_blank"
      >
        BUY in ETH
      </Button>
      &nbsp; &nbsp;
      <Button
        as="a"
        href="https://quickswap.exchange/#/swap?inputCurrency=0xe580074A10360404AF3ABfe2d524D5806D993ea3"
        style={{ backgroundColor: '#17AB76' }}
        target="_blank"
      >
        BUY in Polygon
      </Button>
    </Container>
  )
}
