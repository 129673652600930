import React, { useState, useEffect, useRef, useMemo } from 'react'
import * as ethers from 'ethers'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { PageMeta } from 'components/Layout/Page'

import { ChainId } from 'config/constants/networks'
import { usePayboltContract } from "hooks/usePayboltContract"
import usePreviousValue from 'hooks/usePreviousValue'

import { Wallet, HorizontalBanner, VerticalBanner } from './components'
import { MoneyIcon, ExchangeIcon, UsersIcon } from '../../components/Icons'

const Home: React.FC = () => {
  const { provider, account, chainId } = useWeb3React()
  const payboltContract = usePayboltContract()
  const prevAccount = usePreviousValue(account)
  const prevChainId = usePreviousValue(chainId)

  const [runOnce, setRunOnce] = useState(false)
  const [apiTBalance, setApiTBalance] = useState(0)

  const [payBalance, setPayBalance] = useState(0)
  const [totalTokenEarned, setTotalTokenEarned] = useState(0)

  const [payPrice, setPayPrice] = useState(0)
  const [marketCap, setMarketCap] = useState(0)
  const [holder, setHolder] = useState(0)
  const [change24h, setChange24h] = useState(0)
  const [buybackBal, setBuybackBal] = useState(0)
  const [totalReflection, setTotalReflection] = useState(0)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (account && !runOnce) {
      getPayData(account)
      setRunOnce(true)
    }
  }, [account])

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        setIsLoading(true); 

        try {
          const bpayDecimals = 9
          const payDecimals = 18

          let temp = await payboltContract.holders()
          setHolder(temp)

          if (account) {
            temp = await payboltContract.balanceOf(account)
            const tokenBalance = parseFloat(ethers.utils.formatUnits(temp, payDecimals))
            setPayBalance(tokenBalance)
          }
        } catch (err) {
          console.log('err', err)
        }

        setIsLoading(false); 
      }
    }

    if (payboltContract && account && chainId) {
      fetchData()

      if (prevAccount != account || chainId != prevChainId) {
        // console.log("retrieve now")
        getPayData(account)
      }
    }
  }, [chainId, account, payboltContract])
  
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://widget.nomics.com/embed.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  /*
  useEffect(() => {
    getData()

    const intervalId = setInterval(() => {
      reloadData()
    }, 1000 * 60) // in milliseconds




    const script = document.createElement('script')
    script.src = 'https://widget.nomics.com/embed.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    if (account && account.length >= 20) {
      getAddressBpayStats(account)
    }

    if (!account) {
      setPayBalance(0)
      setTotalTokenEarned(0)
    }
  }, [account])

  const getAddressBpayStats = async (addr: string) => {
    const BNBPAY_API = `https://api.bnbpay.io/user/bpay`

    // get BNBPay
    try {
      const resp = await fetch(BNBPAY_API, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ address: addr }),
      })
      const data = await resp.json()

      setPayBalance(data.balance)
      setTotalTokenEarned(data.earned)
    } catch (e) {
      console.log(`Fetch BNBPAY_API: ${e}`)
    }
  }

  const reloadData = () => {
    getData()

    if (account && account.length >= 20) {
      getAddressBpayStats(account)
    }
  }
  */

  const currencyFormat = (num: number, decimals = 2, prefix = '') => {
    return prefix + (Math.round(num * 100) / 100).toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const getPayData = async (addr: string) => {
    const PAY_API = `https://api.paybolt.io/user/` + addr + `/dapp-stats`

    // get PAY stats
    try {
      const resp = await fetch(PAY_API, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
      const data = await resp.json()

      // console.log(data)
      if (data.status) {
        if (chainId === ChainId.ETHEREUM) {
          setApiTBalance(data.result.eth.t_balance)
        } else if (chainId === ChainId.POLYGON) {
          setApiTBalance(data.result.matic.t_balance)
        } else {
          setApiTBalance(data.result.bsc.t_balance)
        }
        //setPayPrice(data.price)
        //setMarketCap(data.mc)
        //setHolder(data.holder)
        //setChange24h(data.changes_24hr)
        //setBuybackBal(data.buyback_bal)
        //setTotalReflection(data.total_reflection)
      } else {
        setApiTBalance(0)
      }
    } catch (e) {
      console.log(`Fetch PAY_API: ${e}`)
      setApiTBalance(0)
    }
  }

  return (
    <>
      <PageMeta />
      <Content>
        <Title>
          <BlueTitle>Hello</BlueTitle>
        </Title>
        <SubTitle>Welcome back! Here is what’s new while you were away</SubTitle>
        <WalletBlock>
          <Wallet title="PAY Balance" content={currencyFormat(payBalance)} added={!!account} />
          {/*<Wallet title="Total PAY Earned" content={currencyFormat(payBalance - apiTBalance)} added={!!account} />*/}
          <Wallet title="Sell Tax" content="10%" />
        </WalletBlock>
        <HorizontalBanner />
        <MainWrapper>
          <BigBlock>
            <DataItems>
              {/*
              <DataItemWrapper>
                <DataItemIcon>
                  <MoneyIcon />
                </DataItemIcon>
                <DataItem>
                  <DataTitle>Total Paid to Holders</DataTitle>
                  <DataValue>{currencyFormat(totalReflection * payPrice, 0, '$')}</DataValue>
                </DataItem>
              </DataItemWrapper>
              */}
              <DataItemWrapper>
                <DataItemIcon>
                  <UsersIcon />
                </DataItemIcon>
                <DataItem>
                  <DataTitle>Holders</DataTitle>
                  <DataValue>{currencyFormat(holder, 0)}</DataValue>
                </DataItem>
              </DataItemWrapper>
            </DataItems>
            <Divider />
            {/*
            <DataItems>
              <DataItemWrapper>
                <DataItem>
                  <DataTitle>Current Price</DataTitle>
                  <DataValue>${payPrice.toFixed(8)}</DataValue>
                </DataItem>
              </DataItemWrapper>
              <DataItemWrapper>
                <DataItem>
                  <DataTitle>Market Cap</DataTitle>
                  <DataValue>{currencyFormat(marketCap, 2, '$')}</DataValue>
                </DataItem>
              </DataItemWrapper>
              <DataItemWrapper>
                <DataItem>
                  <DataTitle>24hr Change</DataTitle>
                  <DataValue>{change24h}%</DataValue>
                </DataItem>
              </DataItemWrapper>
            </DataItems>
            */}
            <ChartBlock>
              <div style={{ marginBottom: 20, display: chainId === ChainId.MAINNET ? 'block' : 'none' }}>
                <div className="nomics-ticker-widget" data-name="PayBolt" data-base="PAY3" data-quote="USD"></div>
              </div>
              <div style={{ marginBottom: 20, display: chainId === ChainId.POLYGON ? 'block' : 'none' }}>
                <></>
              </div>
              <div style={{ marginBottom: 20, display: chainId === ChainId.ETHEREUM ? 'block' : 'none' }}>
                <></>
              </div>
            </ChartBlock>
          </BigBlock>
          <VerticalBanner />
        </MainWrapper>
      </Content>
    </>
  )
}

const Content = styled.div`
  width: 100%;
  margin-bottom: 50px;
`

const Title = styled.h1`
  font-weight: 600;
  font-size: 25px;
  line-height: 17px;
  letter-spacing: -0.02em;
`

const BlueTitle = styled.span`
  color: #2562ff;
`

const SubTitle = styled.div`
  color: #444f6c;
  font-size: 18px;
  line-height: 17px;
  letter-spacing: -0.02em;
  margin-top: 18px;
`

const WalletBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 0;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 16px 0;
  }
`

const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

const BigBlock = styled.div`
  flex: 1;
  margin-right: 22px;
  @media (max-width: 1200px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
`

const Divider = styled.div`
  width: 100%;
  border: 1px solid #1f2536;
  margin: 40px 0;
  @media (max-width: 768px) {
    margin: 14px 0;
  }
`

const DataItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

const DataItemWrapper = styled.div`
  display: flex;
  flex: 1 1 33.3%;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 50%;
    margin-bottom: 40px;
  }
`

const DataItem = styled.div`
  display: flex;
  flex-direction: column;
`

const DataItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: #1d212d;
  border-radius: 10px;
  margin-right: 16px;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`

const DataTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
  letter-spacing: -0.02em;
  color: #444f6c;
`

const DataValue = styled.h4`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #ffffff;
`

const ChartBlock = styled.div`
  width: 100%;
  margin-top: 40px;
  @media (max-width: 768px) {
    margin-top: 14px;
  }
`

export default Home
