import React from 'react'
import styled from 'styled-components'
import { Button } from 'paybolt-uikit'

import { PlayIcon } from '../../../../components/Icons'

const Container = styled.div`
  display: flex;
  flex: 0 0 353px;
  max-width: 353px;
  min-height: 530px;
  height: 530px;
  width: 100%;
  justify-content: center;
  background-image: url(/images/home/home-banner-vt-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  color: #fff;
`

const Wrapper = styled.div`
  padding-top: 186px;
  margin: 0 auto;
  text-align: center;
`

const Text = styled.span`
  margin-left: 12px;
`

const AppStoreImage = styled.img`
  border-radius: 8px;
  padding: 2px 8px;
  margin: 10px;
  height: 50px;
  width: 128px;
  border: 1px solid #fff;
  background-color: #fff;
`

export default function VerticalBanner() {
  return (
    <Container>
      <Wrapper>
        <a href="https://apps.apple.com/app/paybolt-crypto/id1599880290" target="_blank">
          <AppStoreImage src="/images/home/app-store-icon.svg" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.fincrypt.paybolt" target="_blank">
          <AppStoreImage src="/images/home/google-play-icon.svg" />
        </a>
      </Wrapper>
    </Container>
  )
}
