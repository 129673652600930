import React from 'react'
import styled from 'styled-components'
import { Button } from 'paybolt-uikit'

import { PlusIcon } from '../../../../components/Icons'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 31%;
  border: 1px solid #1f2536;
  border-radius: 12px;
  color: #fff;
  padding: 17px 24px;
  @media (max-width: 768px) {
    flex: 0 0 100%;
    margin: 14px 0;
  }
`

const WalletTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 37px;
  margin-bottom: 21px;
  min-height: 48px;
`

const Description = styled.span`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #8793b3;
`

const WalletValue = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.02em;
`

export default function Wallet({
  title,
  content,
  added = true,
}: {
  title?: string
  content?: string
  added?: boolean
}) {
  return (
    <Container>
      <WalletTitle>{title}</WalletTitle>
      <Wrapper>
        {added ? (
          <ContentWrapper>
            <WalletValue>{content}</WalletValue>
          </ContentWrapper>
        ) : (
          <>
            <ContentWrapper>
              {/*
              <Button style={{ backgroundColor: '#2562FF', height: '40px' }}>
                <PlusIcon />
              </Button>
            */}
            </ContentWrapper>
            <Description>Please connect wallet to view</Description>
          </>
        )}
      </Wrapper>
    </Container>
  )
}
